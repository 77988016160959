import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Біз туралы | Elite Kickboxing Academy туралы
			</title>
			<meta name={"description"} content={"Elite кикбоксинг академиясында біз тек кикбоксингті үйретіп қана қоймаймыз, біз күш, тәртіп пен төзімділік маңызды болатын қауымдастықты дамытып жатырмыз"} />
			<meta property={"og:title"} content={"Біз туралы | Elite Kickboxing Academy туралы"} />
			<meta property={"og:description"} content={"Elite кикбоксинг академиясында біз тек кикбоксингті үйретіп қана қоймаймыз, біз күш, тәртіп пен төзімділік маңызды болатын қауымдастықты дамытып жатырмыз"} />
			<meta property={"og:image"} content={"https://fromanser-kz.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fromanser-kz.com/img/607-607788.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fromanser-kz.com/img/607-607788.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fromanser-kz.com/img/607-607788.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fromanser-kz.com/img/607-607788.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fromanser-kz.com/img/607-607788.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fromanser-kz.com/img/607-607788.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Біз туралы
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				Elite кикбоксинг академиясында біз тек кикбоксингті үйретіп қана қоймаймыз, біз күш, тәртіп пен төзімділік маңызды болатын қауымдастықты дамытып жатырмыз. Біздің академия жекпе-жек өнерінің, әсіресе кикбоксингтің денені де, ақыл-ойды да қалыптастырудағы өзгертетін күшінің куәсі болып табылады.
			</Text>
			<Box
				display="flex"
				margin="40px 0 20px 0"
				justify-content="center"
				sm-flex-direction="column"
				flex-direction="row"
			>
				<Box padding="10px">
					<Image src="https://fromanser-kz.com/img/7.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://fromanser-kz.com/img/8.jpg" width="320px" max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Section background="#ffffff" padding="90px 0 110px 0" quarkly-title="Team-7">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(2, 1fr)"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 80px 0px 0px"
					width="40%"
					lg-padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
					color="--greyD2"
					sm-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--dark" font="600 48px --fontFamily-sans">
						Біздің Философия
					</Text>
					<Text color="--greyD2" font="300 20px/140% --fontFamily-sansHelvetica">
						Біздің ойымызша, кикбоксинг спорт немесе фитнес режимі емес, бұл өмір салты. Біздің назарымыз кикбоксингтің физикалық аспектілерінен асып түседі, бұл біздің әрбір мүшемізге құрмет, табандылық және өзіне деген сенімділік сияқты құндылықтарды қалыптастыруға бағытталған.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					display="flex"
					flex-direction="column"
					lg-width="100%"
				>
					<Box
						min-width="100px"
						min-height="100px"
						justify-self="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-align-items="center"
						display="flex"
						flex-direction="row"
					>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							width="70%"
							padding="0px 0px 0px 50px"
							lg-padding="0px 0px 0px 35px"
							justify-content="center"
							sm-width="100%"
							sm-padding="0px 0px 0px 0"
						>
							<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
								Ерлерге арналған арнайы тренинг
							</Text>
							<Text margin="22px 0px 0px 0px" font="300 18px/140% --fontFamily-sansHelvetica" color="--darkL2" sm-margin="18px 0px 0px 0px">
								Біздің бағдарламалар ер адамдармен көбірек резонанс тудыратын физикалық және психикалық аспектілерге назар аудара отырып, ер адамдарға бағытталған көзқараспен арнайы жасалған.
							</Text>
						</Box>
					</Box>
					<Hr
						min-height="10px"
						min-width="100%"
						margin="50px 0px 40px 0px"
						border-color="--color-lightD2"
						padding="0px 0px 0 0px"
						lg-margin="30px 0px 20px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						justify-self="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-align-items="center"
						display="flex"
						flex-direction="row"
					>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							width="70%"
							padding="0px 0px 0px 50px"
							lg-padding="0px 0px 0px 35px"
							justify-content="center"
							sm-width="100%"
							sm-padding="0px 0px 0px 0"
						>
							<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
								Тәжірибелі нұсқаушылар
							</Text>
							<Text margin="22px 0px 0px 0px" font="300 18px/140% --fontFamily-sansHelvetica" color="--darkL2" sm-margin="18px 0px 0px 0px">
								Біздің кикбоксинг бойынша тәжірибелі мамандардан құралған команда әр мүшенің өзіне қажетті нұсқауларды алуын қамтамасыз етіп, жоғары деңгейдегі дайындық пен тәлімгерлікпен қамтамасыз етуге бағытталған.
							</Text>
						</Box>
					</Box>
					<Hr
						min-height="10px"
						min-width="100%"
						margin="50px 0px 40px 0px"
						border-color="--color-lightD2"
						padding="0px 0px 0 0px"
						lg-margin="30px 0px 20px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						justify-self="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-align-items="center"
						display="flex"
						flex-direction="row"
					>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							width="70%"
							padding="0px 0px 0px 50px"
							lg-padding="0px 0px 0px 35px"
							justify-content="center"
							sm-width="100%"
							sm-padding="0px 0px 0px 0"
						>
							<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
								Біртұтас даму
							</Text>
							<Text margin="22px 0px 0px 0px" font="300 18px/140% --fontFamily-sansHelvetica" color="--darkL2" sm-margin="18px 0px 0px 0px">
								Біз кикбоксингке жан-жақты көзқарасты қамтамасыз ете отырып, психикалық қаттылықтың, стратегиялық ойлаудың және физикалық дайындықтың маңыздылығын атап өтеміз.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://fromanser-kz.com/img/9.jpg"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://fromanser-kz.com/img/0.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Күшті қауымдастық: элиталық шабуылшылар – бұл академия емес – бұл бауырластық. Біз мүшелер бір-бірін ынталандыратын және үйренетін қолдау ортасын қалыптастырамыз.
				</Text>
			</Box>
		</Section>
		<Components.Banner />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});